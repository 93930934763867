import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/banner-list',
			name: 'banner-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerList'),
		},
		{
			path: '/banner-create',
			name: 'banner-create',
			component: () => import('../views/banner/bannerCreate'),
		},
		{
			path: '/banner-edit',
			name: 'banner-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerEdit'),
		},
		{
			path: '/account-list',
			name: 'account-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/account/accountList'),
		},
		{
			path: '/account-create',
			name: 'account-create',
			component: () => import('../views/account/accountCreate'),
		},
		{
			path: '/account-edit',
			name: 'account-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/account/accountEdit'),
		},
		{
			path: '/department-account-list',
			name: 'department-account-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				email: '',
				department_id: ''
			  },
			component: () => import('../views/departmentaccount/dAccountList'),
		},
		{
			path: '/department-account-create',
			name: 'department-account-create',
			component: () => import('../views/departmentaccount/dAccountCreate'),
		},
		{
			path: '/department-account-edit',
			name: 'department-account-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				email: '',
				department_id: ''
			  },
			component: () => import('../views/departmentaccount/dAccountEdit'),
		},
		{
			path: '/department-list',
			name: 'department-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/department/departmentList'),
		},
		{
			path: '/department-create',
			name: 'department-create',
			component: () => import('../views/department/departmentCreate'),
		},
		{
			path: '/department-edit',
			name: 'department-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/department/departmentEdit'),
		},
		{
			path: '/enews-list',
			name: 'enews-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/enews/enewsList'),
		},
		{
			path: '/enews-create',
			name: 'enews-create',
			component: () => import('../views/enews/enewsCreate'),
		},
		{
			path: '/enews-edit',
			name: 'enews-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/enews/enewsEdit'),
		},
		{
			path: '/post-list',
			name: 'post-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/post/postList'),
		},
		{
			path: '/post-create',
			name: 'post-create',
			component: () => import('../views/post/postCreate'),
		},
		{
			path: '/post-edit',
			name: 'post-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/post/postEdit'),
		},
		{
			path: '/post-detail',
			name: 'post-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/post/postDetail'),
		},
		{
			path: '/directory-list',
			name: 'directory-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/directory/directoryList'),
		},
		{
			path: '/directory-create',
			name: 'directory-create',
			component: () => import('../views/directory/directoryCreate'),
		},
		{
			path: '/directory-edit',
			name: 'directory-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/directory/directoryEdit'),
		},
		{
			path: '/directory-detail',
			name: 'directory-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/directory/directoryDetail'),
		},
		{
			path: '/directory-detail-en',
			name: 'directory-detail-en',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/directory/directoryDetailEn'),
		},
		{
			path: '/travel-list',
			name: 'travel-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelList'),
		},
		{
			path: '/travel-create',
			name: 'travel-create',
			component: () => import('../views/travel/travelCreate'),
		},
		{
			path: '/travel-edit',
			name: 'travel-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelEdit'),
		},
		{
			path: '/travel-overview',
			name: 'travel-overview',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelOverview'),
		},
		{
			path: '/travel-history',
			name: 'travel-history',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelHistory'),
		},
		{
			path: '/travel-checkin',
			name: 'travel-checkin',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelCheckin'),
		},
		{
			path: '/travel-photo',
			name: 'travel-photo',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelPhoto'),
		},
		{
			path: '/travel-overview-en',
			name: 'travel-overview-en',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelOverviewEn'),
		},
		{
			path: '/travel-history-en',
			name: 'travel-history-en',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelHistoryEn'),
		},
		{
			path: '/travel-checkin-en',
			name: 'travel-checkin-en',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelCheckinEn'),
		},
		{
			path: '/travel-photo-en',
			name: 'travel-photo-en',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/travel/travelPhotoEn'),
		}

		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router