import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        //Auth
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'admin-login', {...user})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Account
        async accountListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'account-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async accountFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Banner
         async bannerListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'banner-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async bannerFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Department Account
        async dAccountListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'department-account-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async dAccountFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-account-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dAccountStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-account-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dAccountUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-account-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dAccountDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-account-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Department
        async departmentListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'department-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async departmentFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async departmentStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async departmentUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async departmentDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'department-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //E-News
        async enewsListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'enews-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async enewsFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'enews-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async enewsStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'enews-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async enewsUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'enews-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async enewsDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'enews-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Post
        async postListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'post-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async postFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postPinStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-pin-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postDetailListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'post-detail-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async postDetailStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-detail-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postDetailUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-detail-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async postDetailDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'post-detail-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Directory
        async directoryListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'directory-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async directoryFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryPinStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-pin-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryDetailListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'directory-detail-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async directoryDetailStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-detail-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryDetailUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-detail-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async directoryDetailDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'directory-detail-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Travel
        async travelListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'travel-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async travelFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelPinStatusAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-pin-status', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelDetailListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'travel-detail-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async travelDetailStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-detail-store', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelDetailUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-detail-update', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async travelDetailDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'travel-detail-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        }
    },
    modules: {
    }
})
